<!--
  @author Álvaro Fuentes <alvaro.fuentes.zurita@gmail.com>
 -->
<script setup lang="ts">
import { getCurrentInstance, ref, ComponentPublicInstance } from 'vue';
import { Const } from '@afz/base-library';
import { Loginform, CLoginform } from '@afz/base-vue2comps';
import { useAppBus, useAuthBus } from '../../bus/mod.js';

type NotifierEmphasis = 'info' | 'question' | 'success' | 'warning' | 'error';

const { $bvToast } = getCurrentInstance()?.proxy as unknown as ComponentPublicInstance;
const loginRef = ref<Loginform>();
const app = useAppBus();
const auth = useAuthBus();
const busy = ref(false);

function showNotifier(message: string, title?: string, emphasis?: NotifierEmphasis): void {
  const map: Const<Record<NotifierEmphasis, string>> = {
    info: 'default',
    question: 'info',
    success: 'success',
    warning: 'warning',
    error: 'danger',
  };

  $bvToast.toast(message, {
    title: title || 'Error',
    solid: true,
    variant: map[emphasis ?? 'info'],
  });
}

function showAuthError(error: Error): void {
  switch (error.name) {
    case 'UserNameNotFoundError':
    case 'InvalidPassphraseError':
      showNotifier(
        'El nombre de usuario o contraseña es incorrecto. Verifique los datos ingresados y vuelva a intentarlo',
        'Error de acceso',
        'error',
      );
      break;
    default:
      console.error(error);
      showNotifier('Ha ocurrido un error. Por favor intente nuevamente', 'Error desconocido', 'error');
  }
}

async function onLogin(userName: string, passphrase: string): Promise<void> {
  if (!userName || !passphrase) {
    return showNotifier('Debe ingresar usuario y contraseña para continuar', 'Información faltante', 'error');
  }

  busy.value = true;
  try {
    await auth.startSession({
      userName,
      passphrase,
    });

    showNotifier(
      'Usuario debidamente autentificado. Redireccionado a la aplicación. Por favor espere...',
      'Acceso concedido',
      'success',
    );

    setTimeout(function () {
      window.location.href = app.redirectionUrl.toString();
    }, 1000);
  } catch (error) {
    busy.value = false;
    showAuthError(error as Error);
    loginRef.value?.focusPassphrase();
  }
}
</script>

<template>
  <div class="c-login-site">
    <h3 class="c-login-site__title">{{ app.config.uiTitle }}</h3>
    <h4 v-if="!app.config.hideUiSubtitle" class="c-login-site__subtitle">{{ app.config.uiSubtitle }}</h4>
    <c-loginform
      ref="loginRef"
      :busy="busy"
      class="c-login-site__form"
      :user-placeholder="app.config.userPlaceholder"
      @login="onLogin"
    />
  </div>
</template>
