/**
 * @author Álvaro Fuentes <alvaro.fuentes.zurita@gmail.com>
 */
import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { fromBase64 } from '@afz/base-library';
import { config as appConfig, clock } from '../base.js';

export const useAppBus = defineStore('app', function () {
  /**
   * Configuración de aplicación.
   */
  const config = ref(appConfig);

  /**
   * Fecha actual.
   */
  const currentDate = computed(() => clock.now);

  /**
   * URL de origen desde donde se llegó a esta aplicación.
   */
  const refererUrl = ref(undefined as URL | undefined);

  /**
   * Hacia donde redireccionar una vez realizado el login.
   */
  const redirectionUrl = computed(() => refererUrl.value ?? config.value.defaultRedirectionUrl);

  /**
   * Verdadero una vez inicializada toda la infraestructura.
   */
  const inited = ref(false);

  function init(): void {
    // Redirección.
    const currentUrl = new URL(window.location.href);
    const encodedUrl = currentUrl.searchParams.get('r');
    if (encodedUrl) {
      try {
        refererUrl.value = new URL(fromBase64(encodedUrl));
      } catch {
        console.warn(`URL "${fromBase64(encodedUrl)}" inválida`);
      }
    }
    currentUrl.searchParams.delete('r');
    window.history.replaceState(null, '', currentUrl);

    // Inicialización finalizada.
    inited.value = true;
  }

  return {
    config,
    currentDate,
    refererUrl,
    redirectionUrl,
    inited,
    init,
  };
});
