/**
 * @author Álvaro Fuentes <alvaro.fuentes.zurita@gmail.com>
 */
import { defineStore } from 'pinia';
import { isError } from '@afz/base-library';
import { AuthClient } from '@afz/base-auth';
import { API_PUBLIC_KEY } from '../constants.js';
import { api } from '../base.js';

export type CredentialData = {
  userName?: string;

  passphrase?: string;
};

export const useAuthBus = defineStore('auth', function () {
  async function startSession(data: CredentialData): Promise<void> {
    if (!data.userName || !data.passphrase) {
      return;
    }

    const request = await AuthClient.create({ cryptoKey: API_PUBLIC_KEY }).createSessionRequest(
      data.userName,
      data.passphrase,
    );
    if (isError(request)) {
      throw request;
    }

    await api.post('auth/session', request, { credentials: 'include' });
  }

  return { startSession };
});
