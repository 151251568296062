/**
 * @author Álvaro Fuentes <alvaro.fuentes.zurita@gmail.com>
 */
export * from './app.js';
export * from './auth.js';

import { useAppBus } from './app.js';

export async function initBus(): Promise<void> {
  const app = useAppBus();
  await app.init();
}
