/**
 * @author Álvaro Fuentes <alvaro.fuentes.zurita@gmail.com>
 */
import { ComponentOptions, CreateElement, default as Vue } from 'vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import {
  ButtonPlugin,
  BvToast,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  IconsPlugin,
  InputGroupPlugin,
  LayoutPlugin,
  ToastPlugin,
} from 'bootstrap-vue';

Vue.use(PiniaVuePlugin);
Vue.use(ButtonPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormPlugin);
Vue.use(IconsPlugin);
Vue.use(InputGroupPlugin);
Vue.use(LayoutPlugin);
Vue.use(ToastPlugin);

Vue.config.productionTip = true;

declare module 'vue' {
  interface ComponentCustomProperties {
    $bvToast: BvToast;
  }
}

import { initBase } from './base.js';
import { initBus } from './bus/mod.js';
import { router } from './router.js';
import { CRootSite } from './ui/mod.js';
import './assets/style/index.scss';

async function main(): Promise<void> {
  await initBase();
  new Vue({
    pinia: createPinia(),
    router,
    render: (h: CreateElement) => h(CRootSite),
  } as ComponentOptions<Vue>).$mount('#app');
  await initBus();
}

main();
