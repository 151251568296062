/**
 * @author Álvaro Fuentes <alvaro.fuentes.zurita@gmail.com>
 */
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import { CLoginSite } from './ui/mod.js';

export const router = new Router({
  base: import.meta.env.BASE_URL,

  mode: 'history',

  routes: [
    {
      name: 'SsoLogin',
      path: '/',
      component: CLoginSite,
    },
    {
      path: '*',
      redirect: { name: 'SsoLogin' },
    },
  ],
});

declare module 'vue' {
  interface ComponentCustomProperties {
    $router: Router;
  }
}
